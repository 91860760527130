





























































































































































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { ref, defineComponent, computed } from '@vue/composition-api';
import { useUserState } from '@/store';
import axios from 'axios';
import useTeamDoc from '@/composables/useTeamDoc';

export default defineComponent({
  name: 'MDeliverableDefault',
  components: {
    MCreateDeliverable: () => import('@/components/molecules/deliverable/m-create-deliverable.vue'),
    MDeliverableTable: () => import('@/components/molecules/deliverable/m-deliverable-team.vue'),
    MProjectPlan: () => import('@/components/molecules/deliverable/m-project-plan.vue'),
    MCaseStudy: () => import('@/components/molecules/deliverable/m-case-study.vue'),
    MTasks: () => import('@/components/molecules/deliverable/m-tasks.vue'),
    MPresentation: () => import('@/components/molecules/deliverable/m-presentation.vue'),
    MUserFaq: () => import('@/components/molecules/deliverable/m-user-faq.vue'),
    MDigitalResume: () => import('@/components/molecules/deliverable/m-digital-resume.vue'),
    MProjectImplementation: () =>
      import('@/components/molecules/deliverable/m-project-implementation.vue'),
    MUserInterview: () => import('@/components/molecules/deliverable/m-user-interview.vue')
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
    },
    adkType: {
      type: String,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const deliverable = ref('');
    const activeTab: any = ref('');
    const { user } = useUserState(['user']);
    const currentUserId = props.isMonitor ? props.studentId : user.value?._id.toString();
    const teamList: any = ref([]);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);
    const isLoading = ref(false);
    const isFetching = ref(false);
    const isSubmitting = ref(false);
    const currentTabData: any = ref({});
    const onlyView = ref(false);
    const projectPlan = ref('');
    const teamDoc = ref();
    const adminSettings: any = ref();
    const { getTeamDoc }: any = useTeamDoc();
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const adkData = ref(programDoc.value?.data?.adks?.find(a => a.name === props.adkType));
    const presetSettings = computed(() => {
      return adkData.value?.fields?.find(f => f.name === 'm-deliverable-adk')?.preset?.settings;
    });

    async function loadInitialData() {
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
        user_id: props.isMonitor ? props.studentId : user.value?._id.toString(),
        public_id: props?.value?.data?._id
      });
    }
    loadInitialData();

    function viewOnly() {
      const isMember = currentTabData.value?.members?.filter(m => m._id === currentUserId);
      if (
        currentUserId === currentTabData.value?.ownerId ||
        (isMember.length && !isMember[0].isMembershipPending)
      ) {
        onlyView.value = false;
      } else {
        onlyView.value = true;
      }

      if (props.isMonitor) {
        onlyView.value = true;
      }
    }
    async function fetchDeliverableData(val) {
      if (val.ownerId && val.programId) {
        isFetching.value = true;
        try {
          const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
          const res = await axios.get(
            `${ENDPOINT_API}?deliverable_id=${val._id}&user_id=${val.ownerId}&program_id=${val.programId}&public_id=${props?.value?.data?._id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
              }
            }
          );
          if (res.data.data) {
            currentTabData.value = res.data.data;
            isFetching.value = false;
            viewOnly();
          }
        } catch (error) {
          console.log(error);
          isFetching.value = false;
        }
      }
    }
    async function fetchDeliverableAdminSetting() {
      try {
        isLoading.value = true;
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_ADMIN;
        const res = await axios.get(
          `${ENDPOINT_API}?type=deliverable_setting&public_id=${programDoc.value?.data?._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data.data) {
          adminSettings.value = res.data.data.deliverableSettings;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }
    async function fetchDeliverables() {
      try {
        teamList.value = [];
        teamList.value.push({
          _id: 0,
          name: 'New',
          icon: 'mdi-note-plus-outline'
        });
        const tab = activeTab.value;
        isLoading.value = true;
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_ALL;
        const res = await axios.get(
          `${ENDPOINT_API}?program_id=${
            ctx.root.$route.params.programId || programDoc.value?.data?.program_id
          }&public_id=${programDoc.value?.data?._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data.data) {
          await fetchDeliverableAdminSetting();
          activeTab.value = tab;
          const list = res.data.data;
          list.forEach(ele => {
            if (ele.ownerId === currentUserId) {
              teamList.value.splice(1, 0, ele);
            } else {
              teamList.value.push(ele);
            }
          });
          await fetchDeliverableData(teamList.value[1]);
          activeTab.value = 1;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }
    fetchDeliverables();

    function updateDeliverable(val) {
      const index = teamList.value.findIndex(t => t._id === val._id);
      teamList.value[index].name = val.name;
    }

    return {
      setupInstructions,
      updateDeliverable,
      programDoc,
      fetchDeliverableData,
      showInstructions,
      deliverable,
      teamList,
      activeTab,
      adminSettings,
      fetchDeliverables,
      isLoading,
      user,
      currentUserId,
      currentTabData,
      isFetching,
      viewOnly,
      onlyView,
      projectPlan,
      isSubmitting,
      fetchDeliverableAdminSetting,
      teamDoc,
      presetSettings
    };
  }
});
