var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""}},[_c('v-container',{staticClass:"module-default pa-0"},[_c('v-overlay',{staticClass:"border-r",staticStyle:{"width":"100%"},attrs:{"z-index":100,"absolute":"","value":_vm.isLoading || _vm.isFetching,"opacity":0.15}},[_c('v-progress-circular',{attrs:{"size":"80","width":"5","indeterminate":"","color":"green"}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{attrs:{"tile":"","depressed":""}},[_c('v-tabs',{attrs:{"slider-color":"green","next-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-double-left","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.teamList),function(item,index){return _c('v-tab',{key:item._id,on:{"click":function($event){return _vm.fetchDeliverableData(item)}}},[(item._id === 0)?_c('v-icon',{attrs:{"color":_vm.activeTab === item._id ? "green" : '',"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):(item.ownerId === _vm.currentUserId)?_c('v-icon',{attrs:{"color":"yellow","left":""}},[_vm._v(" mdi-star ")]):_c('div',[(
                    item.members.filter(function (m) { return m._id === _vm.currentUserId && !m.isMembershipPending; })
                      .length
                  )?_c('v-icon',{attrs:{"color":_vm.activeTab === index ? "green" : '',"left":""}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{attrs:{"color":_vm.activeTab === index ? "green" : '',"left":""}},[_vm._v(" mdi-eye ")])],1),_c('span',{staticClass:"font-weight-bold",class:_vm.activeTab === index ? "green--text" : ''},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.teamList),function(item){return _c('v-tab-item',{key:item._id},[_c('v-card',{attrs:{"flat":""}},[(_vm.activeTab === 0)?_c('m-create-deliverable',_vm._g({attrs:{"student":_vm.programDoc,"is-monitor":_vm.isMonitor,"fetch-deliverables":_vm.fetchDeliverables,"team-name":_vm.teamDoc && _vm.teamDoc.name}},_vm.$listeners)):_vm._e(),(item._id !== 0)?_c('v-expansion-panels',{attrs:{"accordion":""}},[(
                      _vm.adminSettings.teamSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.teamSettings.required &&
                        _vm.adminSettings.team !== 'global_on' &&
                        _vm.adminSettings.team !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-account-group")]),_vm._v("Team ")],1)]),_c('v-expansion-panel-content',[(_vm.currentTabData)?_c('m-deliverable-table',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView,"is-monitor":_vm.isMonitor,"student":_vm.programDoc,"fetch-deliverables":_vm.fetchDeliverables,"fetch-deliverable":_vm.fetchDeliverableData},on:{"set-tab":function($event){_vm.activeTab = 0},"update":_vm.updateDeliverable}}):_vm._e()],1)],1):_vm._e(),(
                      _vm.adminSettings.projectPlanSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        _vm.presetSettings.projectPlanSettings.required &&
                        _vm.adminSettings.projectPlan !== 'global_on' &&
                        _vm.adminSettings.projectPlan !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-file-document")]),_vm._v("Project Plan ")],1)]),_c('v-expansion-panel-content',[(_vm.currentTabData)?_c('m-project-plan',{ref:"projectPlan",refInFor:true,attrs:{"adk-list":_vm.currentTabData.adks,"fetch-deliverables":_vm.fetchDeliverables,"view-only":_vm.onlyView,"data":_vm.currentTabData}}):_vm._e()],1)],1):_vm._e(),(
                      _vm.adminSettings.myTasksSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.projectTaskSettings.required &&
                        _vm.adminSettings.myTasks !== 'global_on' &&
                        _vm.adminSettings.myTasks !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-format-list-checks")]),_vm._v("My Tasks ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-0"},[_c('m-tasks',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.FAQSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.projectFAQSettings &&
                        _vm.adminSettings.FAQ !== 'global_on' &&
                        _vm.adminSettings.FAQ !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-chat-question")]),_vm._v("Frequently Asked Questions (FAQ) ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"},[_c('m-user-faq',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.digitalResumeSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.digitalResumeSettings &&
                        _vm.adminSettings.digitalResume !== 'global_on' &&
                        _vm.adminSettings.digitalResume !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-file-document-multiple")]),_vm._v("Digital Resume ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"},[_c('m-digital-resume',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.finalPresentationSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.presentationSettings.required &&
                        _vm.adminSettings.finalPresentation !== 'global_on' &&
                        _vm.adminSettings.finalPresentation !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-presentation")]),_vm._v("Final Presentation ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{},[_c('m-presentation',{attrs:{"data":_vm.currentTabData,"adk-type":"deliverable","view-only":_vm.onlyView,"color":"green"},model:{value:(_vm.programDoc),callback:function ($$v) {_vm.programDoc=$$v},expression:"programDoc"}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.marketingBlogSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.caseStudySettings.required &&
                        _vm.adminSettings.marketingBlog !== 'global_on' &&
                        _vm.adminSettings.marketingBlog !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-lead-pencil")]),_vm._v("Marketing Blog ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6 pb-12"},[_c('m-case-study',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.projectDocumentationSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.projectImplementationSettings.required &&
                        _vm.adminSettings.projectDocumentationSetting !== 'global_on' &&
                        _vm.adminSettings.projectDocumentationSetting !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-wrench-clock")]),_vm._v("Project Documentation ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"},[_c('m-project-implementation',{attrs:{"view-only":_vm.onlyView,"adk-type":"deliverable"},model:{value:(_vm.programDoc),callback:function ($$v) {_vm.programDoc=$$v},expression:"programDoc"}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.userInterviewSetting === 'global_on' ||
                      (_vm.presetSettings &&
                        !!_vm.presetSettings.userInterviewSettings.required &&
                        _vm.adminSettings.userInterview !== 'global_on' &&
                        _vm.adminSettings.userInterview !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-chat-processing")]),_vm._v("User Interviews ")],1)]),_c('v-expansion-panel-content',[_c('m-user-interview',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1):_vm._e(),_c('v-expansion-panel',{attrs:{"disabled":""}},[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-alert-decagram")]),_vm._v("Additional coming soon... ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"})],1)],1)],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }